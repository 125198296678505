import React from 'react'

import Layout from "../components/Layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <p>
    Seja bem-vindo ao Portal Aluizio Developer! Este tem como objetivo trazer conteúdos do mundo da tecnologia da informação, curiosidades, estilos de vida, desenvolvimento pessoal, além de outras coisas que possam ser interessantes para as pessoas de forma geral.
    </p>
    <p>
    O fato de trazer conteúdos de assuntos bem abrangentes é por conta da rápida evolução de tudo que estudamos e fazemos no mundo globalizado em que vivemos. Falar só de tecnologia seria algo tedioso para muitos leitores, pois para boa parte das pessoas hoje, a tecnologia está inerente ao dia a dia laboral.
    </p>
    <p>
    Então, nosso intuito é variar o conteúdo de forma a proporcionar conteúdos casuais, engraçados e curiosos, além de tecnológicos também.
    </p>
  </Layout>
)

export default AboutPage